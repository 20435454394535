<style scoped>
.middle_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.categories_filter {
  /* width: 150px; */
  flex-grow: 0;
  margin-right: 3px;
  margin-left: 3px;
}

.search_box {
  flex-grow: 2;
  width: 20%;
  margin-right: 3px;
  margin-left: 3px;
}
</style>
<template>
  <header class="pb-md-4 pb-0" :class="{ 'active': stick && sticky }">
    <HeaderElementsTopHeader :themeOptions="themeOptions" v-if="themeOptions?.header?.page_top_bar_enable" />
    <div class="top-nav top-header sticky-header">
      <div class="container-fluid-lg">
        <div class="row">
          <div class="col-12">
            <div class="navbar-top">
              <div class="d-xl-none d-inline-flex">
                <HeaderElementsNavMenuButton @activeMenu="toggle($event)" :show="active" />
              </div>
              <HeaderElementsLogo :themeOptions="themeOptions" :textClass="'txt-theme f-w-600'" :logo="logo" />
              <div class="middle_box">
   
                <div class="search_box d-none d-sm-none d-md-block" style="">
                  <HeaderElementsSearch />
                </div>
              </div>
              <div class="rightside-box">
                <ul class="right-side-menu">
                  <li class="right-side">
                    <HeaderElementsSearchBox />
                  </li>
                  <li class="right-side" v-if="themeOptions?.header?.support_number">
                    <HeaderElementsSupport :themeOptions="themeOptions" />
                  </li>
                  <!-- <li class="right-side">
                    <HeaderElementsWishlist />
                  </li> -->
                  <li class="right-side mx-3">
                  </li>
                  <li>
                    <div class="onhover-dropdown header-badge" style="display: flex;">
                      <HeaderElementsCarts />

                    </div>
                  </li>
                  <li class="right-side onhover-dropdown">
                    
                    <div class="onhover-dropdown header-badge" style="display: flex;">
                      <HeaderElementsMyAccount />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid-lg">
      <div class="row">
        <div class="col-12">
          <div class="header-nav">
            <div class="header-nav-left">
              <HeaderElementsCategory :data="themeOptions" />
            </div>
            <div class="header-nav-middle">
              <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                <div class="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu" :class="{ 'show': active }">
                  <div class="offcanvas-header navbar-shadow">
                    <h5>{{ $t('Menu') }}</h5>
                    <button :type="'button'" :id="'toggle_menu_btn'" @click="toggle(false)" :class="'btn-close lead'">
                      <Icon name="ri:close-fill" />
                    </button>
                  </div>
                  <div class="offcanvas-body">
                    <ul class="navbar-nav">
                      <li class="nav-item" style="font-size: 18px;">
                        <nuxt-link class="dropdown-item show" to="/" >Home</nuxt-link>
                      </li>
                      <li class="nav-item" style="font-size: 18px;">
                        <nuxt-link class="dropdown-item show" style="cursor: pointer;" @click="cartStore.toggleCartsDrawer" >Cart</nuxt-link>
                      </li>
                      <li class="nav-item" style="font-size: 18px;">
                        <nuxt-link class="dropdown-item show" to="/order/orders" >Orders</nuxt-link>
                      </li>
                      <li class="nav-item" style="font-size: 18px;" v-if="authStore.isLoggedIn">
                        <nuxt-link class="dropdown-item show" to="/auth/dashboard" >Dashboard</nuxt-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="offcanvas-backdrop" v-if="active" :class="{ 'show': active }" @click="toggle(false)">
                </div>
              </div>
            </div>

            <div class="header-nav-right" v-if="themeOptions?.header?.today_deals.length">
              <HeaderElementsTodayDeal />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
import { useAuthStore } from '~/lib/features/auth/store/auth_store';
import { useCartStore } from '~/lib/features/cart/store/cart_store';

// import { Menu } from '@/data/menu'
const authStore = useAuthStore()
const cartStore = useCartStore()
let props = defineProps({
  themeOptions: Object,
  logo: String,
  sticky: Boolean,
})

let stick = ref(false);
let active = ref(false);

function toggle(value) {
  active.value = value;
}

const onWindowScroll = () => {
  let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  if (number >= 150 && window.innerWidth > 400) {
    stick.value = true;
  } else {
    stick.value = false;
  }
};

onMounted(() => {
  window.addEventListener('scroll', onWindowScroll);
});
</script>